import axios from 'axios'
import { Message } from 'element-ui'
import router from "@/router/index.js";
import { getLoginHeader } from '@/libs/tools.js';
import store from '../store';
import i18n from '@/lang/index.js';
const instance = axios.create({
    // baseURL: process.env.NODE_ENV === "production" ? "/" : "/api",
    baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_API : "/api"
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let header = getLoginHeader();
    config.headers = header;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if(error.response.status == 500){
        Message({
            message: i18n.t('common.Error_500'),
            type: 'error',
        })
    }else if(error.response.status == 401){ //登录失效
        store.commit("user/clearToken");
        let url = error.response.request.responseURL
        if (url.indexOf('/front/personal') == -1) {
            Message({
                message: i18n.t('common.Error_401'),
                type: 'error',
            })
            router.push('/login');
        }
    }
    return Promise.reject(error);
});

export default instance