import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLogin, isEmpty } from "@/libs/tools.js";
import store from "@/store";

Vue.use(VueRouter)
/**
 * meta上的login为true则页面需要登录验证
 */
const routes = [
    {
        path: '/',
        redirect: '/online-exhibition'
    },
    //登录
    {
        path: '/login',
        name: 'login',
        component: () => import("@/views/login/index.vue")
    },
    //采购商注册
    {
        path: '/register',
        name: 'register',
        component: () => import("@/views/register/index.vue")
    },
    //采购商注册成功
    {
        path: '/register-success',
        name: 'register-success',
        component: () => import("@/views/register/success.vue")
    },
    {
        path: '/goodsinfo',
        name: 'goodsinfo',
        component: () => import("@/views/goodsinfo/index.vue")
    },
    {
        path: '/goodsinfo/swiper',
        name: 'swiper',
        component: () => import("@/views/goodsinfo/swiper")
    },
    //线上观展
    {
        path: '/online-exhibition',
        name: 'online-exhibition',
        component: () => import("@/views/online-exhibition/index.vue")
    },
    // 展会介绍
    {
        path: '/introduction',
        name: 'introduction',
        meta:{
            cache: true,    //用于判断页面是否缓存
        },
        component: () => import("@/views/introduction/index.vue")
    },
    // 展会现场
    {
        path: '/scene',
        name: 'scene',
        component: () => import("@/views/scene/index.vue")
    },
    //参展商详情
    {
        path: '/exhibitor',
        name: 'exhibitor',
        component: () => import("@/views/exhibitor/index.vue")
    },
    // 择样车
    {
        path: '/cart',
        name: 'cart',
        meta: { login: true },
        component: () => import("@/views/cart/index.vue")
    },
    //询价列表
    {
        path: '/inquiry',
        name: 'inquiry',
        meta: { login: true },
        component: () => import("@/views/inquiry/index.vue")
    }
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: '/index',
    routes
})

router.beforeEach((to, from, next) => {
    if (!isEmpty(to.meta.login) && to.meta.login == true && !isLogin()) {
        next({name: "login", query: { redirect: to.fullPath }});
    } else {
        next();
    }
})

export default router
