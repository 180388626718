export default {
    common: {
        cue: '提示',
        operate_login_cue: '该操作需要登录！',
        confirm_text: '确认',
        cancel_text: '取消',
        hasCart: '已在择样车中',
        leastSelectOne: '至少选中一项',
        Error_500: '服务器内部错误',
        Error_401: '登录失效',
        qrCode: '扫码直达展会现场',
        signUpQrCode: '观展商报名\r\n点击报名',
        emptyData: '暂无数据',
        title8:'第22届中国汕头（澄海）国际玩具礼品博览会暨第5届澄海玩具机械展、首届IP创意授权及衍生品展',
        title9: '澄海玩具展',
    },
    header:{
        goHome: '回到首页',
        welcome: '欢迎来到澄海玩具展',
        loginButton: '登录',
        registerButton: '采购商注册',
        loginWelcome: '您好！',
        logoutButton: '退出',
        language: '语言',
        menu1: '线上观展',
        menu2: '展会介绍',
        menu3: '展会现场',
        menu4: '霄鸟云',
        menu5: '择样车',
        menu6: '询价列表',
    },
    footer:{
        title1: '主办单位：',
        content1: [
            '中国国际商会',
            '中国轻工工艺品进出口商会',
            '中国玩具和婴童用品协会',
            '汕头市澄海区玩具协会'
        ],
        title2: '承办单位：',
        content2: [
            '汕头市中玩商务展览有限公司',
        ],
        title3: '指导单位：',
        content3: [
            '汕头市人民政府',
            '澄海区人民政府',
        ],
        title5: '支持单位：',
        content5: [
            '汕头市商务局',
            '汕头市工业和信息化局',
            '汕头市外事局',
            '中国版权保护中心',
            '中国礼仪休闲用品工业协会',
            '广东国际商会',
            '广东省玩具协会',
            '广东省动漫行业协会',
            '汕头国际商会',
            '汕头市电子商务产业协会',
            '汕头市澄海区工艺美术协会',
            '汕头市澄海区玩具礼品国际品牌发展中心',
        ],
        title7: '澄海玩具展：',
        title8: '友情链接',
        content8: [
          {name: '霄鸟云', url: 'https://www.toysaas.com/'},
          // https://xnapp.toysaas.com
          {name: '小竹熊', url: 'https://www.inewtoys.com/'},
          {name: '智玩汇', url: 'http://www.zwhtoys.com/'},
          {name: '玩具说', url: 'https://www.toysol.com/'},
          {name: '玩具巴巴', url: 'https://www.toybaba.com/'},
        ]
    },
    page:{
        prevPage: '上一页',
        nextPage: '下一页',
        skipLeft: '到第',
        skipRight: '页',
        skipButton: '确认',
    },
    login:{
        welcome: '欢迎登录',
        fieldText1: '请输入手机号码',
        fieldText1Required: '请输入手机号码',
        fieldText2: '请输入密码',
        fieldText2Required: '请输入密码',
        loginButton: '登录',
        noAccount: '没有账号？',
        register: '立即注册'
    },
    register:{
        explain: '采购商注册',
        fieldText1: '请填写公司名称',
        fieldText1Required: '请填写公司名称',
        fieldText2: '请填写联系人',
        fieldText2Required: '请填写联系人',
        fieldText3: '请填写联系手机',
        fieldText3Required: '请填写联系手机',
        checkFieldText3Format: '联系手机格式错误',
        fieldText4: '请填写密码',
        fieldText4Required: '请填写密码',
        registerButton: '立即注册',
        hasAccount: '已有账号？',
        login: '立即登录'
    },
    registerSuccess:{
        explain1: '恭喜您，您的',
        explain2: '采购商账号',
        explain3: '注册成功',
        button: '马上登录'
    },
    onlineExhibition:{
        searchSelect1: '展商名称',
        searchSelect2: '展位号',
        searchKeyword: '请输入关键字搜索',
        searchButton: '搜索',
        contact: '联系人',
        mobile: '联系手机',
        article_number: '货号',
        address: '联系地址',
        booth: '展位信息',
    },
    exhibitor:{
        booth: '展位信息：',
        contact: '联系人：',
        qq: '联系QQ：',
        telephone: '联系电话：',
        type: '主营类型：',
        mobile: '联系手机：',
        address: '联系地址：',
        cert: '厂家证书：',
        intro: '厂家介绍：',
        article_number: '货号：',
        button_all: '全选',
        button_all_cancel: '取消全选',
        button_add: '批量加入择样',
        video_nonsupport : '您的浏览器不支持Video标签。',
        video_title: '视频',
        more_toy: '更多产品请访问霄鸟云',
    },
    cart: {
        selectAll: '全选',
        productName: '产品名称',
        information: '产品信息',
        operation: '操作',
        packaging: '包装方式',
        productSize: '产品规格',
        packingSize: '包装规格',
        outsideBoxVolume: '外箱体积',
        cartonVolume: '外箱材积',
        cartonSize: '外箱规格',
        quantity: '外箱装量',
        innerBoxVolume: '内盒个数',
        grossNetWeight: '毛/净重',
        inqueryButton: '询价',
        deleteButton: '删除',
        batDeleteButton: '批量删除',
        gatherText1: '当前择样车存在',
        gatherText2: '款产品，已选中',
        gatherText3: '款产品',
        batInqueryButton: '批量提交询价',
        delete_cue: '确认删除？',
        emptyData: '当前择样车还没有产品',
    },
    inquiry: {
        bat_delete: '批量删除',
        main_picture:'图片',
        name:'产品名称',
        article_number: '产品货号',
        company_name: '参展商名称',
        mobile: '联系手机',
        inquiry_count: '询价箱数',
        inquiry_content: '备注',
        inquiry_time: '询价时间',
        operate: '操作',
        operate_1: '重新询价',
        operate_2: '删除',
        delete_cue: '确认删除？',
    },
    inquiry_dialog:{
        title: '重新询价',
        article_number_label: '厂家货号：',
        inquiry_count_lable: '询价箱数：',
        fieldText1Required: '请输入询价箱数',
        remark_label: '备注：',
        button1: '取消',
        button2: '提交',
    },
    bat_inquiry_dialog:{
        title: '询价',
        name:'产品名称',
        article_number: '厂家货号',
        company_name: '厂家名称',
        inquiry_count: '询价箱数',
        fieldText1Required: '请输入询价箱数',
        remark: '备注',
        operate: '操作',
        remove: '移除',
        button1: '取消',
        button2: '提交',  
        batSetCount: '批量修改箱数',
        batSetCountTitle: '批量设置询价箱数',
        batSetCountLabel: '修改箱数',
        batSetRemark: '批量设置备注',
        batSetRemarkTitle: '批量设置询价备注',
        batSetRemarkLabel: '修改备注',
    },
    goodsinfo:{  
        article_number: "厂家货号", 
        packing: "包装方式",
        packing_size:'包装规格',
        info_size:"产品规格",
        outer_box:"外箱规格",
        carton_quantity: "装箱量", 
        carton_cubic_meter: "外箱体积", 
        carton_cubic_feet: "外箱材积", 
        inbox_count: "内盒个数", 
        gross_weight: "毛重/净重", 
        certificate: "证书",  
        Shopping_cart:"加入择样车",
        enquiry:'询价',
        mobile: "联系手机", 
        telephone: "联系电话", 
        contact: "联系人", 
        boothinfo:"展位信息",
        productdetails:"产品详情",
        submit:"提交",
        cancel:'取消',
        inquiry:"询问箱数",
        remarks:"备注",
        number:'查询数量大于一',
        remarks:"备注",
        enquiryprice:"询价",
        number:'询问箱数大于0或者等于0',
        remarks:"备注",
        loading:"加载中",
        deletecart:"移除择样车",
    },
}