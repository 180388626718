import router from '@/router';
import {isEmpty} from "@/libs/tools.js";
export default {
    namespaced: true,
    state: {
        cache: [],
    },
    getters: {

    },
    mutations: {
        setCache(state) {
            //遍历路由列表，把有meta.cache=true的缓存起来
            // state.cache = router.options.routes.map((currentValue)=>{
            //     if(!isEmpty(currentValue.meta) && !isEmpty(currentValue.meta.cache) && currentValue.meta.cache == true){
            //         return currentValue.name;
            //     }
            // });
            let tmp = [];
            for(let i=0; i< router.options.routes.length; i++){
                let currentValue = router.options.routes[i];
                if(!isEmpty(currentValue.meta) && !isEmpty(currentValue.meta.cache) && currentValue.meta.cache == true){
                    tmp.push(currentValue.name);
                }
            }
            state.cache = tmp;
        },
    },
    actions: {
        
    }
};
