import { login, logout, personal } from "@/api/user.js";
import Cookies from 'js-cookie'
import { isEmpty, isLogin } from "@/libs/tools.js";
export default {
    namespaced: true,
    state: {
        token: '',
        userItem: null,
    },
    getters: {
        getToken(state){
            if(isEmpty(state.token)) state.token = Cookies.get('token');
            return state.token;
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            Cookies.set('token', token);
        },
        clearToken(state){
            state.token = '';
            Cookies.remove('token');
        },
        setUserItem(state, item){
            state.userItem = item;
        }
    },
    actions: {
        // user login
        login({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                login(data).then(res => {
                    if(res.code == 0){
                        commit('setToken', res.token);
                        //初始化用户信息
                        dispatch('personal');
                    }
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // user logout
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(res => {
                    commit('clearToken')
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        //用户信息
        personal({ commit, dispatch, state }){
            return new Promise((resolve, reject) => {
                if(state.userItem == null && isLogin()){
                    personal().then(res => {
                        if(res.code == 0){
                            commit('setUserItem', res.data);
                            dispatch('cart/getNum', {}, {root: true});
                        }
                        resolve(res)
                    }).catch(error => {
                        reject(error)
                    })
                }else{
                    resolve({code: 0, data: state.userItem});
                }
            })
        }
    }
};
