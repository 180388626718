import axios from '@/libs/axios';
import { Cart } from './variable.js';

//添加选样车
export const add = (data) => {
    return axios.request({
        url: Cart.add,
        data: data,
        method: 'post'
    });
};
//选样车列表
export const list = (data) => {
    return axios.request({
        url: Cart.list,
        data: data,
        method: 'post'
    });
};
//删除选样车
export const del = (data) => {
    return axios.request({
        url: Cart.del,
        data: data,
        method: 'post'
    });
};

//选样车数量
export const cardNum = (data) => {
    return axios.request({
        url: Cart.cardNum,
        data: data,
        method: 'post'
    });
};