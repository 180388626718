export const User = {
    login: '/front/login',          //用户登录
    register: '/front/register',    //注册
    logout: '/front/logout',         //退出
    personal: '/front/personal',    //个人信息
}

export const OnlineExhibition = {
    list: '/front/exhibitor_list',  //参展商列表
    pavilion: '/front/exhibitor_pavilion', //展馆类型
}

export const Scence = {
    list: '/front/scene_list',  //展会现场列表
    qr: '/front/indexPic',      //二维码
}

export const Exhibitor = {
    read: '/front/exhibitor_read',  //参展商主页
}

export const Cart = {
    add: '/front/cartAdd',  //添加选样车
    list: '/front/cart_list',   //选样车列表
    del: '/front/cart_delete',  //选样车删除
    cardNum: '/front/cardNum',  //选样车数量
}
export const Goodsinfo = {
    info: '/front/toy_detail', //产品信息
}

export const Inquiry = {
    list: '/front/inquiry_list',        //询价列表
    update: '/front/inquiry_update',    //重新询价
    del: '/front/inquiry_delete',       //询价删除
    inquiry: '/front/inquiry',              //询价
}