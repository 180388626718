export default {
    common: {
        cue: 'Info',
        operate_login_cue: 'This operation requires a login!',
        confirm_text: 'confirm',
        cancel_text: 'cancel',
        hasCart: 'Already in cart',
        leastSelectOne: 'Select at least one item',
        Error_500: 'Internal service error',
        Error_401: 'Login Expiration',
        qrCode: 'Scan to\r\nexhibition live',
        signUpQrCode: 'Observator registration\r\nClick to register',
        emptyData: 'No Data',
        title8:'The 22nd China Shantou (Chenghai) International Toy&Gift Fair, The 5th Chenghai Toy Machinery Exhibition and The 1st IP Creative Licengsing and Line of Products Exhibition',
        title9: 'CHENGHAI TOYS FAIR',
    },
    header:{
        goHome: 'Back To Home Page',
        welcome: 'Welcome To Chenghai Toy Exhibition',
        loginButton: 'Sign In',
        registerButton: 'Register',
        loginWelcome: 'Hello!',
        logoutButton: 'logout',
        language: 'language',
        menu1: 'Exhibition List',
        menu2: 'Introduction',
        menu3: 'Exhibition',
        menu4: 'SkyBird Cloud',
        menu5: 'Cart',
        menu6: 'Inquiry list',
    },
    footer:{
        title1: 'Sponsors:',
        content1: [
            'China Chamber of International Commerce',
            'China Chamber of Commerce for Import and Export of Light Industrial Products and Arts-Crafts',
            'China Toy & Juvenile Products Association',
            'Chenghai Toys Association'
        ],
        title2: 'Organizer:',
        content2: [
            'Shantou Zhongwan Commercial Exhibition Co., Ltd.',
        ],
        title3: 'Guiding unit:',
        content3: [
            'Shantou Municipal Government',
            "Chenghai District People's Government"
        ],
        title5: 'Supporting units:',
        content5: [
            'Bureau of Commerce of Shantou Municipality',
            'Bureau of Industry and Information Technology of Shantou Municipality',
            'Shantou Foreign Affairs Bureau',
            'Copyright Protection Center of China',
            'China Gift & Leisure Products Association',
            'China Council for the Promotion of International Trade Guangdong Committee',
            'Guangdong Toys Association',
            'Guangdong Animation & Comic Industry Association',
            'Shantou International Commerce',
            'Shantou E-commerce Industry Association',
            'Shantou Arts & Crafts Association',
            'Shantou International Brand Development Center for Toys & Gifts',
        ],
        title7: 'Chenghai Toys Expo:',
        title8: 'Friendly Links',
        content8: [
          {name: 'Skybird Cloud', url: 'https://www.toysaas.com/'},
          {name: 'XiaoZhuXiong', url: 'https://www.inewtoys.com/'},
          {name: 'Toy Gathering', url: 'http://www.zwhtoys.com/'},
          {name: 'Toyshow.Top', url: 'https://www.toysol.com/'},
          {name: 'Toybaba', url: 'https://www.toybaba.com/'},
        ]
    },
    page:{
        prevPage: 'Page-up',
        nextPage: 'Next',
        skipLeft: 'Goto',
        skipRight: '',
        skipButton: 'Go',
    },
    login:{
        welcome: 'Welcome To Login',
        fieldText1: 'Mobile',
        fieldText1Required: 'Mobile required',
        fieldText2: 'Password',
        fieldText2Required: 'Password required',
        loginButton: 'Sign In',
        register: 'Create account'
    },
    register:{
        explain: 'Register',
        fieldText1: 'Company Name',
        fieldText1Required: 'Company Name required',
        fieldText2: 'Contacts',
        fieldText2Required: 'Contacts required',
        fieldText3: 'Mobile',
        fieldText3Required: 'Mobile required',
        checkFieldText3Format: 'Mobile format error',
        fieldText4: 'Password',
        fieldText4Required: 'Password required',
        registerButton: 'Register Now',
        hasAccount: 'Already a customer?',
        login: 'Sign-In.'
    },
    registerSuccess:{
        explain1: 'Congratulations, your account registered successfully',
        button: 'Sign-In Now'
    },
    onlineExhibition:{
        searchSelect1: 'Name',
        searchSelect2: 'Booth',
        searchKeyword: 'Please enter keyword search',
        searchButton: 'Search',
        contact: 'Contacts',
        mobile: 'Mobile Phone',
        article_number: 'No',
        address: 'Address',
        booth: 'Booth information',
    },
    exhibitor:{
        booth: 'Booth information：',
        contact: 'Contacts：',
        qq: 'QQ：',
        telephone: 'Telephone：',
        type: 'Main business type：',
        mobile: 'Mobile：',
        address: 'Address：',
        cert: 'Certificatation：',
        intro: 'Introduction：',
        article_number: 'NO：',
        button_all: 'Select all',
        button_all_cancel: 'Cancel all',
        button_add: 'Batch join',
        video_nonsupport : 'Your browser does not support Video tags.',
        video_title: 'Video',
        more_toy: 'For more products please click to Skybird',
    },
    cart: {
        selectAll: 'Select All',
        productName: 'Product Name',
        information: 'Information',
        operation: 'Operation',
        packaging: 'Packaging',
        productSize: 'Product Size',
        packingSize: 'Packing Size',
        outsideBoxVolume: 'Outside Box Volume',
        cartonVolume: 'Carton Volume',
        cartonSize: 'Carton Size',
        quantity: 'Quantity',
        innerBoxVolume: 'Inner Box Volume',
        grossNetWeight: 'Gross/Net Weight',
        inqueryButton: 'Inquery',
        deleteButton: 'Delete',
        batDeleteButton: 'Bulk Delete',
        gatherText1: '',
        gatherText2: 'products in sample cart',
        gatherText3: 'Selected',
        batInqueryButton: 'Submit Bulk Inquiry',
        delete_cue: 'Confirm Deletion?',
        emptyData: 'No Data',
    },
    inquiry: {
        bat_delete: 'Bulk Delete',
        main_picture:'Pic',
        name:'Product name',
        article_number: 'NO',
        company_name: 'Exhibitor name',
        mobile: 'Mobile',
        inquiry_count: 'Inquiry (Ctn)',
        inquiry_content: 'Remarks',
        inquiry_time: 'Inquiry time',
        operate: 'operation',
        operate_1: 'Inquiry',
        operate_2: 'Delete',
        delete_cue: 'Confirm Deletion?',
    },
    inquiry_dialog:{
        title: 'Inquiry',
        article_number_label: 'NO：',
        inquiry_count_lable: 'Inquiry (Ctn) ：',
        fieldText1Required: 'Inquiry (Ctn) required',
        remark_label: 'Remarks：',
        button1: 'cancel',
        button2: 'Submit',
    },
    bat_inquiry_dialog:{
        title: 'Inquiry',
        name:'Product Name',
        article_number: 'NO',
        company_name: 'Name',
        inquiry_count: 'Inquiry (Ctn)',
        fieldText1Required: 'Inquiry (Ctn) required',
        remark: 'Remarks',
        operate: 'Operation',
        remove: 'Delete',
        button1: 'Cancel',
        button2: 'Submit',
        batSetCount: 'Bulk modify the numbers of cartons',
        batSetCountTitle: 'Modify numbers of cartons',
        batSetCountLabel: 'Modify numbers of cartons',
        batSetRemark: 'Bulk set remarks',
        batSetRemarkTitle: 'Modify remark',
        batSetRemarkLabel: 'Modify remark',
    },
    goodsinfo:{  
        article_number: "NO", 
        packing: "Packaging",
        packing_size:'Packing size',
        info_size:"Product specification",
        outer_box:"Carton size",
        carton_quantity: "Quantity", 
        carton_cubic_meter: "Outside Box Volume", 
        carton_cubic_feet: "Carton volume", 
        inbox_count: "Inner Box Volume", 
        gross_weight: "Gross / Net weight", 
        certificate: "Certificatation", 
        Shopping_cart:"Join Cart",
        enquiry:'Inquiry',
        mobile: "Mobile",
        telephone: "Telephone", 
        contact: "Contacts", 
        boothinfo:"Booth information",
        productdetails:'Product Details',
        submit:"submit",
        cancel:'cancel',
        inquiry:"inquiry(Ctn)",
        remarks:"Remarks",
        number:'Quantity greater than one',
        enquiryprice:" enquiryprice",
        number:'Quantity greater than zero or equal to zero',
        loading:"Loading" ,
        deletecart:"Remove",
},
}