<template>
    <div id="app">
        <keep-alive :include="cache">
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {getLanguage} from '@/lang/index.js'
export default {
    data() {
        return {
            
        }
    },
    mounted() {
        this.$store.commit('routerData/setCache');
        let body = document.querySelector('body');
        if (getLanguage() == 'zh' ) {
            body.style.fontFamily = 'Microsoft YaHei'
        } else {
            body.style.fontFamily = 'Arial'
        }
    },
    methods:{
        
    },
    computed: {
        ...mapState({
            cache: state => state.routerData.cache,
        })
    },
}
</script>
<style lang="less">
body {
    margin: 0;
    padding: 0; 
    font-family: Microsoft YaHei Arial;
}
</style>
