import Vue from 'vue'
import Vuex from 'vuex'
import app from './module/app.js'
import routerData from './module/routerData.js'
import user from './module/user.js'
import cart from './module/cart.js'
import qrcode from './module/qrcode.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    modules: {
        app,
        routerData,
        user,
        cart,
        qrcode,
    }
})
