import axios from '@/libs/axios';
import { User } from './variable.js';

//登录
export const login = (data) => {
    return axios.request({
        url: User.login,
        data: data,
        method: 'post'
    });
};

//注册
export const register = (data) => {
    return axios.request({
        url: User.register,
        data: data,
        method: 'post'
    });
};

//退出
export const logout = () => {
    return axios.request({
        url: User.logout,
        method: 'post'
    });
};

//个人信息
export const personal = () => {
    return axios.request({
        url: User.personal,
        method: 'post'
    });
}