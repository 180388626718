import { qr } from "@/api/scene.js";
import { Message } from 'element-ui';
const state = {
    open: false,
    url: null,
}

const mutations = {
    SET_QR_CODE_OPEN: (state, open) => {
        state.open = open;
    },
    SET_QR_CODE_URL: (state, url) => {
        state.url = url;
    }
}

const actions = {
    initQrCode({ commit, state }) {
        if(state.url == null){
            qr().then(res => {
                if (res.code == 0) {
                    let open = res.data.is_open_live == '1' ? true : false;
                    let url = res.data.qr_code;
                    commit("SET_QR_CODE_OPEN", open);
                    commit("SET_QR_CODE_URL", url);
                } else {
                    Message.error(res.msg);
                }
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}