import { add, cardNum } from "@/api/cart.js";
import Cookies from 'js-cookie'
import { isEmpty, isLogin } from "@/libs/tools.js";
import { MessageBox } from 'element-ui';
import i18n from '@/lang/index.js';
import router from "../../router";

export default {
    namespaced: true,
    state: {
        num:0,
    },
    mutations: {
        setNum(state, num){
            state.num = num;
        }
    },
    actions: {
        // 添加选样车
        add({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                if(!isLogin()){ //没登录提示
                    MessageBox.confirm(i18n.t('common.operate_login_cue'), i18n.t('common.cue'), {
                        confirmButtonText: i18n.t('common.confirm_text'),
                        cancelButtonText: i18n.t('common.cancel_text'),
                        customClass: 'operate-login-cue',
                        center: true
                    }).then(() => {
                        router.push({name: 'login', query: {redirect: router.app._route.fullPath}});
                    }).catch(() => {
                       
                        // reject(new Error('cancel'));
                    });
                }else{  //登录直接加
                    add(data).then(res => {
                        if(res.code == 0){
                            dispatch('getNum');
                        }
                        resolve(res)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        //获取选样车数量
        getNum({commit}, data){
            cardNum({}).then(res=>{
                if(res.code == 0){
                    commit('setNum', res.data);
                }else{
                    commit('setNum', 0);
                }
            })
        }
    }
};
