import { getLanguage } from "@/lang/index.js";
import store from "@/store/index.js";
import router from "@/router"
/**
 * 是否为空
 * @param {*} data 
 * @returns 
 */
export const isEmpty = function (data) {
    if (data == undefined || data == null || data == '') {
        return true;
    } else {
        return false;
    }
}

/**
 * 是否是手机号
 * @param {*} $poneInput 
 * @returns 
 */
export const isMobile = function (val) {
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(val)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 设置缓存
 * @param {*} key 
 * @param {*} data 
 */
export const setCache = function (key, data) {
    if (data instanceof Object) {
        data = JSON.stringify(data);
    }
    localStorage.setItem(key, data);
}

/**
 * 获取缓存
 * @param {*} key 
 * @returns 
 */
export const getCache = function (key) {
    let data = localStorage.getItem(key);
    try {
        data = JSON.parse(data);
    } catch (e) {

    }
    return data;
}

/**
 * 删除缓存
 * @param {*} key 
 */
export const deleteCache = function (key) {
    localStorage.removeItem(key);
}

/**
 * 获取当前语言对应的数据
 * @param {*} data 格式 {zh: 'xxx', en: 'xxxxx'}
 * @param {*} checkEmpty 校验空，空的拿中文填充
 */
export const getLanguageData = function (data, checkEmpty = false) {
    let lang = getLanguage();
    if (data[lang] != undefined && !isEmpty(data[lang]) && JSON.stringify(data[lang]) != '{}'){//表格的插槽scope.row会把null转成{}此处多一个判断
        return data[lang];
    } else if (!checkEmpty) {
        return data[lang];
    } else {
        return data['zh'];
    }
}

/**
 * 获取登录的header
 */
export const getLoginHeader = function () {
    if (isLogin()) {
        return {
            token: store.getters['user/getToken'],
        };
    } else {
        return {};
    }
}

/**
 * 返回当前是否登录状态
 */
export const isLogin = function () {
    let token = store.getters['user/getToken'];
    if (isEmpty(token)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 深拷贝
 * @param {*} obj 
 * @returns 
 */
export const deepCopy = function(obj) {
    let result = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] !== null && typeof obj[key] === 'object') {
                result[key] = deepCopy(obj[key]);   // 递归复制
            } else {
                result[key] = obj[key];
            }
        }
    }
    return result;
}

/**
 * 跳转路由
 * @param {*} path 
 * @param {*} query 
 */
export const jump = function(path, query = {}) {
    router.push({path: path, query: query})
}