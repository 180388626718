import axios from '@/libs/axios';
import { Scence } from './variable.js';

//展会现场列表
export const list = (data) => {
    return axios.request({
        url: Scence.list,
        data: data,
        method: 'post'
    });
};

//直播二维码
export const qr = (data) => {
    return axios.request({
        url: Scence.qr,
        data: data,
        method: 'post'
    });
};